import { SuccessModal } from '@guider-global/ui';
import { useContext } from 'react';
import { SurveyModalsContext } from '../SurveyModalsContext';

import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

export function ThankYouSurveyModalContainer() {
  const { isSurveySubmitted, resetState } = useContext(SurveyModalsContext);
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();

  return (
    <SuccessModal
      paperSx={{ minWidth: { md: '600px', xs: '100vw' } }}
      open={isSurveySubmitted}
      heading={
        baseLanguage.surveys?.response_submitted_modal?.title ?? 'Thank you!'
      }
      subheading={
        baseLanguage.surveys?.response_submitted_modal?.description ??
        'Your rating has been received'
      }
      closeButtonLabel={
        baseLanguage.globals?.common?.close_button_label ?? 'Close'
      }
      onClose={resetState}
    />
  );
}
